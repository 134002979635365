<nz-sider class="menu-sidebar" nzCollapsible nzWidth="256px" nzMode="inline" [(nzCollapsed)]="isCollapsed"
  [nzTrigger]="null" [ngClass]="isCollapsed == true ?'is-collapsed-hide':''">
  <div class="sidebar-logo">
    <h1 class="main-logo">Sperry App Tracker</h1>
  </div>

  <ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed" class="sidebar-menu">
    <ng-container>
      <li class="advance-search">
        <form nz-form [formGroup]="appTrackerForm">
          <div class="row filters-list">
            <nz-form-item>
              <nz-form-label [nzSpan]="24">
                PSL
              </nz-form-label>
            <nz-form-control>
              <nz-select
                nzPlaceHolder="Select PSL"
                formControlName="selectedPSL">
              
              <nz-option *ngFor="let i of (userSelectionPSLEnum | enumKeyValueList)" [nzValue]="i.index" [nzLabel]="i.name"></nz-option>
              </nz-select>
            </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label [nzSpan]="24">
                Region
              </nz-form-label>
                <nz-form-control [nzSpan]="24">
                <nz-select [nzMaxTagCount]="3" [nzMaxTagPlaceholder]="tagPlaceHolder" nzMode="multiple"
                  nzPlaceHolder="All" formControlName="selectedHALRegion">

                  <nz-option *ngFor="let i of (userSelectionHALRegionEnum | enumKeyValueList)" [nzValue]="i.index"
                    [nzLabel]="i.name"></nz-option>
                </nz-select>
                <ng-template #tagPlaceHolder let-selectedList>and {{ selectedList.length }} more selected</ng-template>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label [nzSpan]="24">
                Last Updated Hours
              </nz-form-label>

              <nz-form-control [nzSpan]="24">
                <nz-input-group nzAddOnAfter="Hours">
                  <input nz-input type="number" min="0" [(ngModel)]="selectedHours"
                    onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                    [ngModelOptions]="{standalone: true}" />
                </nz-input-group> 
              </nz-form-control>
            </nz-form-item>

            <nz-form-item nz-row class="register-area">
              <nz-form-control [nzSpan]="24">
                <button nz-button nzType="primary" (click)="search()">Search</button>
              </nz-form-control>
            </nz-form-item>
          </div>
        </form>


      </li>
      

      <li class="advance-search" *ngIf="filterData != null && filterData != undefined && filterData.wells.length!=0">
        <div class="heading">
          <h3>Local Filtering</h3>
        </div>
          <form nz-form [formGroup]="localFilteringForm">
            <div class="row filters-list" >
              <nz-form-item>
                <nz-form-label [nzSpan]="24">
                  Well
                </nz-form-label>
                <nz-form-control [nzSpan]="24">
                  <nz-select [nzMaxTagCount]="3" [nzMaxTagPlaceholder]="tagPlaceHolder" nzMode="multiple"
                    nzPlaceHolder="All" formControlName="selectedWell" (ngModelChange) = "onModifyWell()">
                    <nz-option *ngFor="let i of wells" [nzValue]="i" [nzLabel]="i"></nz-option>
                  </nz-select>
                  <ng-template #tagPlaceHolder let-selectedList>and {{ selectedList.length }} more selected
                  </ng-template>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label [nzSpan]="24">
                  Application
                </nz-form-label>

                <nz-form-control [nzSpan]="24" *ngIf="appDetailsVal && appDetailsVal.length > 0">
                  <nz-select [nzMaxTagCount]="3" [nzMaxTagPlaceholder]="tagPlaceHolder" nzMode="multiple"
                    [nzPlaceHolder]="appDetailsVal" formControlName="selectedApplication" (ngModelChange) = "onModifyApp()">
                    <nz-option  [nzValue]="appDetailsVal" [nzLabel]="appDetailsVal"></nz-option>
                  </nz-select>
                  <ng-template #tagPlaceHolder let-selectedList>and {{ selectedList.length }} more selected
                  </ng-template>
                </nz-form-control>

                <nz-form-control [nzSpan]="24" *ngIf="appDetailsVal && appDetailsVal.length <= 0">
                  <nz-select [nzMaxTagCount]="3" [nzMaxTagPlaceholder]="tagPlaceHolder" nzMode="multiple"
                    nzPlaceHolder="All" formControlName="selectedApplication" (ngModelChange) = "onModifyApp()">
                    <nz-option *ngFor="let i of apps" [nzValue]="i" [nzLabel]="i"></nz-option>
                  </nz-select>
                  <ng-template #tagPlaceHolder let-selectedList>and {{ selectedList.length }} more selected
                  </ng-template>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label [nzSpan]="24">
                  Version
                </nz-form-label>

                <nz-form-control [nzSpan]="24">
                  <nz-select [nzMaxTagCount]="3" [nzMaxTagPlaceholder]="tagPlaceHolder" nzMode="multiple"
                    nzPlaceHolder="All" formControlName="selectedVersion" (ngModelChange) = "onModifyVerion()">
                    <nz-option *ngFor="let i of versions" [nzValue]="i" [nzLabel]="i"></nz-option>
                  </nz-select>
                  <ng-template #tagPlaceHolder let-selectedList>and {{ selectedList.length }} more selected
                  </ng-template>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item nz-row class="register-area">
                <nz-form-control [nzSpan]="24">
                  <button nz-button nzType="primary" (click)="filter()">Filter</button>
                </nz-form-control>
              </nz-form-item>
            </div>
          </form>
      </li>
      <li>
        <div class="refreshCheckbox" >
          <label class="lbl" nz-checkbox [(ngModel)]="checked" (ngModelChange)="onChangeBox()">Enable Page Refresh Automatically</label>
        </div>
      </li>
      <li style="width: 100%;
      display: flex;
      margin: 10px 0px;">
        <div>
          <small style="color:#f8e80b;padding-top: 10px;">Note: Interval Time is {{pageRefreshInterval/(60*1000)}} mins</small>
        </div>
      </li>
    </ng-container>
  </ul>

</nz-sider>