export const environment = {
  production: true,
  apiUrl: window.location.protocol+"//apptracker-api.ienergy.halliburton.com/api/v1/",
  signalRUrl: window.location.protocol + "//apptracker-api.ienergy.halliburton.com/",

  
  CLIENT_ID : '0oas8awfgrAIwchZO2p7',
  ISSUER : 'https://myapps.halliburton.com/oauth2/auss8b1wseHEg7bCG2p7',
  LOGIN_REDIRECT_URI : window.location.origin + '/login/callback',
  // LOGOUT_REDIRECT_URI : window.location.origin + '/logout',
  LOGOUT_REDIRECT_URI : window.location.origin,

  PageRefreshInterval : 5*60*1000 /* in min */
};
