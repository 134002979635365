export enum UserSelectionPSLEnum {
	// HPM = 0,
	// PS = 1,
	// PE = 2,
	// TSS = 3,
	// PPS = 4,
	// BAR = 5,
	// CEM = 6,
	// MC = 7,
	// AL = 8,
	// LMK = 9,
	SDS = 10,
	// WPS = 11,
	// HCT = 12,
	// TTT = 13,
	// DBS = 14,
	// All = 99,
}
