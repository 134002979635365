import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { map } from "rxjs/operators"
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AppInstanceKey } from '../shared/Models/AppInstanceKey';
import { AppInstanceSearchParameters } from '../shared/Models/AppInstanceSearchParameters';
import { AppTrackerAPIResult } from '../shared/Models/AppTrackerAPIResult';
import { AppInstanceInfo } from '../shared/Models/AppInstanceInfo';
import { FilterData } from '../models/FilterData';

@Injectable({
    providedIn: 'root'
})
export class ServerCommunicationsService { 

    private apiUrl = environment.apiUrl;
    public newSearchRequestReceived = new EventEmitter<AppInstanceSearchParameters>();
    public newFilterRequestReceived = new EventEmitter<Array<string>>();
    public newFilterDataReceived = new EventEmitter<FilterData>();
    public updaedUserDetailsEvnt = new EventEmitter<any>();
    public appDetailsEvent= new EventEmitter<Array<string>>();

    constructor(
        private _http: HttpClient,
        private notification: NzNotificationService
    ) {
    }

    getVersion(): Observable<any> {
        return this._http.get(this.apiUrl + 'Version/GetVersion', { responseType: 'text' });
    }

    updateUserDetails(user) {
        this.updaedUserDetailsEvnt.next(user)
    }

    getResultPerInstance(appInstanceKey: AppInstanceKey): Observable<AppInstanceInfo> {
        return this._http.post<AppInstanceInfo>(this.apiUrl + 'Results/GetResultPerInstance', appInstanceKey).pipe(map((data:any) =>{
            const mxAAPIResult: AppTrackerAPIResult = data;
            if(mxAAPIResult.state === true){                
                return mxAAPIResult.result;
            }
            else{                                
                retry(1),catchError(this.handleError)               
            }            
        }));
    }

    getActiveInstances(appInstanceSearchParameters: AppInstanceSearchParameters): Observable<Array<AppInstanceInfo>> {
        return this._http.post<Array<AppInstanceInfo>>(this.apiUrl + 'Results/GetActiveInstances', appInstanceSearchParameters).pipe(map((data:any) =>{
            const mxAAPIResult: AppTrackerAPIResult = data;
            if(mxAAPIResult.state === true){                
                return mxAAPIResult.result;
            }
            else{                                
                retry(1),catchError(this.handleError)               
            }            
        }));
    }

    handleError(error: HttpErrorResponse) {
        let errorMessage = 'Unknown error!';
        if (error.error instanceof ErrorEvent) {
            // Client-side errors
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side errors
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}\nResponseError: ${error.error}`;
        }
        return throwError(errorMessage);
    }
}