export enum UserSelectionHALRegionEnum {
	Unknown = 0,
	AP = 1,
	MENA = 2,
	GOM = 3,
	EESSA = 4,
	LA = 5,
	NAL = 6,
	NUS = 7,
	All = 99,
}
