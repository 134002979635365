import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  isSidebarVisible: boolean;
  sidebarVisibilityChange: Subject<boolean> = new Subject<boolean>();

  constructor() { 
    this.sidebarVisibilityChange.subscribe((value) => {
      this.isSidebarVisible = value
    });
  }

  toggleSidebarVisibility() {
    var tttt;
    this.sidebarVisibilityChange.next(!this.isSidebarVisible);
    tttt = this.isSidebarVisible;
  }
}
