import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { HttpClientModule } from '@angular/common/http';
import { EnumKeyValueListPipe } from '../Pipes/enum-key-value.pipe';
import { FilterPipe } from '../Pipes/filter.pipe';
import { SortPipe } from '../Pipes/sort.pipe';

@NgModule({
  declarations: [
    EnumKeyValueListPipe,
    FilterPipe,
    SortPipe
  ],
  imports: [    
    CommonModule,
    HttpClientModule,
  ],
  providers: [{ provide: NZ_I18N, useValue: en_US }],
  exports: [
    EnumKeyValueListPipe
  ]
})
export class SharedModule {}
