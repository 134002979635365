<nz-header>
	<div class="app-header">
		<div>
			<span class="header-trigger" (click)="toggleSidebar()">
				<i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i>
			</span>
		</div>

		<div class="username" *ngIf="userInfo !== null">
			<span>{{userInfo.name}}</span>
			<a nz-dropdown nzTrigger="click" [nzDropdownMenu]="userInfoMenu" class="user-info"
				*ngIf="userInfo !== null">
				<nz-avatar nzIcon="user" style="background-color:#0084FF;"></nz-avatar>
				<i nz-icon nzType="caret-down" nzTheme="outline"></i>
			</a>
		</div>

	</div>

</nz-header>

<nz-dropdown-menu #userInfoMenu="nzDropdownMenu">
	<ul nz-menu *ngIf="userInfo !== null" class="user-dropdown">
		<li nz-menu-item (click)="logout()">
			Logout
		</li>
	</ul>
</nz-dropdown-menu>