import { Inject, Injectable, Injector } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NzNotificationService } from "ng-zorro-antd/notification";
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

declare function loadingServiceShow(zindex, id, flag, msg);
declare function loadingServiceHide(id);

@Injectable()

export class TokenInterceptorService implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public accessToken = "";
  public accessTokenExp: number = 0;
  refreshTokenTimeout;

  constructor(
    private injector: Injector,
    // public oktaAuth: OktaAuth,
    public _router: Router,
    private _notification: NzNotificationService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
  ) {
    // this.accessToken = this.oktaAuth.getAccessToken();
    // this.accessTokenExp = this.oktaAuth.token.decode(this.accessToken).payload.exp
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    const token = this._oktaAuth.getAccessToken();
    if (localStorage.getItem('okta-token-storage') !== null) {
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token
          }
        });
      }
    }
    else {
      const token = "";
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        loadingServiceHide("addwellbtn");
        this._notification.error(
          "",
          error.message,
          {
            nzClass: "_notification-class",
            nzPlacement: "topRight",
            nzDuration: 2000,
          });
        if (error.status === 401) {
          
          this._router.navigate(["/home"]);
        }
        return throwError(error);
      }));
  }

  async logout() {
    this._router.navigate(["/home"]);
  }

}