import { UserSelectionHALRegionEnum } from "../Enums/UserSelectionHALRegionEnum";
import { UserSelectionPSLEnum } from "../Enums/UserSelectionPSLEnum";

export class AppInstanceSearchParameters
{
	public activeInstanceLastInHours: number = 8;
	public hALRegionFilter: Array<UserSelectionHALRegionEnum> = [UserSelectionHALRegionEnum.All];
	public pSLFilter: Array<UserSelectionPSLEnum> = [UserSelectionPSLEnum.SDS];
	public applicationName:string = null;
}
