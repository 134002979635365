import { Component, OnInit, Renderer2 } from '@angular/core';
import { FilterData } from '../models/FilterData';
import { HomeComponent } from '../pages/home/home.component';
import { ServerCommunicationsService } from '../services/serverCommunications.service';
import { UserSelectionHALRegionEnum } from '../shared/Enums/UserSelectionHALRegionEnum';
import { UserSelectionPSLEnum } from '../shared/Enums/UserSelectionPSLEnum';
import { AppInstanceSearchParameters } from '../shared/Models/AppInstanceSearchParameters';
import { ThemeService } from '../theme.service';
import { SidebarService } from './sidebar.service';
import _ from "lodash";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Combo } from '../shared/Models/Combo';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less']
})
export class SidebarComponent implements OnInit {
  public userSelectionHALRegionEnum = UserSelectionHALRegionEnum;
  public userSelectionPSLEnum = UserSelectionPSLEnum;
  public version: string;
  public selectedHours: number = 8;
  public wells: Array<string> = new Array<string>();
  public apps: Array<string> = new Array<string>();
  public versions: Array<string> = new Array<string>();
  public combos: Array<Combo> = new Array<Combo>();
  public filterData: FilterData;
  isCollapsed = false;
  public appDetailsVal: Array<string> = new Array<string>();
  public pageRefreshInterval: number = environment.PageRefreshInterval;

  public checked: boolean = JSON.parse(localStorage.getItem('isAutoRefreshEnable'));

  appTrackerForm: FormGroup;
  localFilteringForm: FormGroup;

  allChecked = false;
  indeterminate = false;
  dropdownList = [];
  private _myInterval: any

  constructor(
    private fb: FormBuilder,
    private themeService: ThemeService,
    private sidebarservice: SidebarService,
    private serverCommunicationsService: ServerCommunicationsService,
    private renderer: Renderer2,

  ) {

    this.sidebarservice.sidebarVisibilityChange.subscribe(value => {
      this.isCollapsed = !value;
    });
    this.serverCommunicationsService.appDetailsEvent.subscribe((data: Array<string>) => {
      this.appDetailsVal = data;
    })
    this.serverCommunicationsService.newFilterDataReceived.subscribe(filterData => {
      this.filterData = filterData;
      this.wells = Object.assign(filterData.wells);
      if (this.appDetailsVal && this.appDetailsVal.length > 0) {
        this.apps = this.appDetailsVal;
        this.onModifyApp();
      }
      else {
        this.apps = Object.assign(filterData.applications);
      }
      this.versions = Object.assign(filterData.versions);
      this.combos = Object.assign(filterData.combos);
    });
    this.getVersion();
  }

  ngOnInit(): void {

    this.appTrackerForm = this.fb.group({
      selectedPSL: [this.userSelectionPSLEnum.SDS],
      selectedHALRegion: [],
    });

    this.localFilteringForm = this.fb.group({
      selectedWell: [],
      selectedApplication: [],
      selectedVersion: [],
    });

    for (let i: number = 0; i < (Object.keys(UserSelectionPSLEnum).length / 2) - 1; i++) {
      this.dropdownList.push({ item_id: UserSelectionPSLEnum[UserSelectionPSLEnum[i]], item_text: UserSelectionPSLEnum[i] });
    }

    window.onload = () => {
      const ele = document.querySelector('.loader');
      this.renderer.addClass(ele, 'hidden');
    };
    this.onChangeBox();

  }

  public onChangeBox() {
    if (this.checked === true) {
      this._myInterval = setInterval(() => {
        document.location.reload();
      }, this.pageRefreshInterval);
    } else {
      if (this._myInterval !== undefined && this._myInterval !== null) {
        clearInterval(this._myInterval);
      }
    }
    localStorage.setItem('isAutoRefreshEnable', JSON.stringify(this.checked));
  }

  toggleTheme(): void {
    this.themeService.toggleTheme().then();
  }

  public getVersion() {
    this.serverCommunicationsService.getVersion().subscribe(data => {
      this.version = data;
    });
  }

  public search() {
    let appInstanceSearchParameters: AppInstanceSearchParameters = new AppInstanceSearchParameters();
    let selectedPSl: Array<UserSelectionPSLEnum> = this.appTrackerForm.controls.selectedPSL.value;
    let selectedHAL: Array<UserSelectionHALRegionEnum> = this.appTrackerForm.controls.selectedHALRegion.value;
    // if(selectedPSl.findIndex(a => a == this.userSelectionPSLEnum.All) < 0){
    //   appInstanceSearchParameters.pSLFilter = selectedPSl
    // }
    if (selectedHAL != null && selectedHAL != undefined && selectedHAL.findIndex(a => a == this.userSelectionHALRegionEnum.All) < 0) {
      appInstanceSearchParameters.hALRegionFilter = selectedHAL
    }
    appInstanceSearchParameters.activeInstanceLastInHours = this.selectedHours;
    this.serverCommunicationsService.newSearchRequestReceived.emit(appInstanceSearchParameters);
    this.localFilteringForm.get('selectedWell').setValue([]);
    this.localFilteringForm.get('selectedApplication').setValue([]);
    this.localFilteringForm.get('selectedVersion').setValue([]);
  }

  onModifyWell() {
    let currentSelectedWells: Array<string> = this.localFilteringForm.controls.selectedWell.value;
    if (currentSelectedWells != null && currentSelectedWells.length > 0) {
      if (currentSelectedWells.length > 0) {
        this.apps = new Array<string>();
        this.versions = new Array<string>();
        currentSelectedWells.forEach(well => {
          this.filterData.combos.forEach(combo => {
            if (well == combo.well) {
              // this.apps.push(combo.app);
              let i = this.apps.findIndex((data) => data === combo.app)
              if (i === -1) {
                this.apps.push(combo.app);
              }
              let j = this.versions.findIndex((data) => data === combo.version)
              if (j === -1) {

                this.versions.push(combo.version);
              }
            }
          });
        });
      }
    }
    else {
      this.apps = this.filterData.applications;
      this.versions = this.filterData.versions;
    }
    this.versions = _.union(this.versions);
  }

  onModifyApp() {
    if (this.appDetailsVal && this.appDetailsVal.length > 0) {
      var currentSelectedApps: Array<string> = this.appDetailsVal;
    }
    else {
      var currentSelectedApps: Array<string> = this.localFilteringForm.controls.selectedApplication.value;
    }
    if (currentSelectedApps != null && currentSelectedApps.length > 0) {
      if (currentSelectedApps.length > 0) {
        this.wells = new Array<string>();
        this.versions = new Array<string>();
        if (typeof (currentSelectedApps) !== "string") {
          currentSelectedApps.forEach(app => {
            this.filterData.combos.forEach(combo => {
              if (app == combo.app) {
                // this.wells.push(combo.well);
                // this.versions.push(combo.version);
                let i = this.versions.findIndex((data) => data === combo.version)
                if (i === -1) {

                  this.versions.push(combo.version);
                }
                let j = this.wells.findIndex((data) => data === combo.well)
                if (j === -1) {

                  this.wells.push(combo.well);
                }
              }
            });
          });

        }
        else {
          this.filterData.combos.forEach(combo => {
            if (currentSelectedApps.toString() == combo.app) {
              // this.wells.push(combo.well);
              // this.versions.push(combo.version);
              let i = this.versions.findIndex((data) => data === combo.version)
              if (i === -1) {

                this.versions.push(combo.version);
              }
              let j = this.wells.findIndex((data) => data === combo.well)
              if (j === -1) {

                this.wells.push(combo.well);
              }
            }
          });
          let filteredApp: Array<string> = this.localFilteringForm.controls.selectedWell.value;
          if (filteredApp == null || filteredApp.length == 0) {
            filteredApp = this.wells
          }
          this.serverCommunicationsService.newFilterRequestReceived.emit(filteredApp);
        }
      }
    }
    else {
      this.wells = this.filterData.wells;
      this.versions = this.filterData.versions;
    }
    this.versions = _.union(this.versions);
  }

  onModifyVerion() {
    let currentSelectedVersions: Array<string> = this.localFilteringForm.controls.selectedVersion.value;
    if (currentSelectedVersions != null && currentSelectedVersions.length > 0) {
      if (currentSelectedVersions.length > 0) {
        this.wells = new Array<string>();
        this.apps = new Array<string>();
        currentSelectedVersions.forEach(version => {
          this.filterData.combos.forEach(combo => {
            if (version == combo.version) {
              //this.wells.push(combo.well);

              let i = this.apps.findIndex((data) => data === combo.app)
              if (i === -1) {

                this.apps.push(combo.app);
              }
              let j = this.wells.findIndex((data) => data === combo.well)
              if (j === -1) {

                this.wells.push(combo.well);
              }
              this.versions.push(combo.version);
            }
          });
        });
      }
    }
    else {
      this.wells = this.filterData.wells;
      this.apps = this.filterData.applications;
    }
    this.getVersion();
    this.versions = _.union(this.versions);
  }

  public filter() {
    let filteredWells: Array<string> = this.localFilteringForm.controls.selectedWell.value;
    if (filteredWells == null || filteredWells.length == 0) {
      filteredWells = this.wells
    }
    this.serverCommunicationsService.newFilterRequestReceived.emit(filteredWells);
  }
}

