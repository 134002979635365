import { Component, Inject, OnInit } from '@angular/core';
import { SidebarService } from '../sidebar/sidebar.service';
import { ThemeService } from '../theme.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ServerCommunicationsService } from '../services/serverCommunications.service';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuthService } from '../services/oktaAuth.service';
import { Router } from '@angular/router';
import { OktaAuth } from '@okta/okta-auth-js';


declare function loadingServiceShow(zindex, id, flag);
declare function loadingServiceHide(id);

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.less']
})

export class HeaderComponent implements OnInit {

	public userInfo: any = null;
	public userName: string = "";
	isCollapsed = false;
	constructor(
		private themeService: ThemeService,
		private sidebarservice: SidebarService,
		private serverCommunicationsService: ServerCommunicationsService,
		private notification: NzNotificationService,
		public oktaAuthService: OktaAuthService,
		@Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
		public authService: OktaAuthStateService,
		private _router: Router
	) { 
		this.subscribeForUserInformation();
	}

	async ngOnInit() {
		
	}

	subscribeForUserInformation() {
		this.serverCommunicationsService.updaedUserDetailsEvnt.subscribe((data)=>{
			this.userInfo = data;
		})
	}	

	toggleSidebar() {
		this.sidebarservice.toggleSidebarVisibility()
		this.isCollapsed = this.sidebarservice.isSidebarVisible;
	}

	toggleTheme(): void {
		this.themeService.toggleTheme().then();
	}

	
	public logout() {
		 this.oktaAuthService.logout();
	  }

	get isSidebarVisible(): boolean {
		return this.sidebarservice.isSidebarVisible;
	}
}