<div class="grid-container">
  <div class="app-header">
    <app-header></app-header>
  </div>

  <div class="app-sidebar">
    <app-sidebar></app-sidebar>
  </div>

  <div class="router-outlet">
     <router-outlet></router-outlet>
  </div>  

  <div class="app-footer">
    <app-footer></app-footer>
  </div>
</div>


